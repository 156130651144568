import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
//import Slider from 'infinite-react-carousel';
import { Suspense, useEffect, useRef } from 'react';
import LoginSlider from '../components/shared/LoginSlider';
import SkyLoadingPanel from "../components/shared/SkyLoadingPanel";
import { AzureAdB2C, loginRequest } from '../RAFAzure/AuthModule';
import { ContentType } from '../RAFComponents/helpers/Constants';
import { getQueryString, isNotNullAndUndefined, IsNullOrWhiteSpace } from '../RAFComponents/helpers/utils';
import { showErrorToast } from '../RAFComponents/Utility/RAFToastComponent';
//import * as repositoryActions from '../RAFComponents/store/actions/repositoryActions';
import "./../styles/Login.scss";
import * as repositoryActions from "../RAFComponents/store/actions/repositoryActions";
import { EmpLoginStatusValue, UserLoginFlow } from '../helpers/SkyConstants';
import "./LoginPageStyle.scss";
import { default as microsoftLogo } from "./../images/Microsoft_logo.svg";
import { Logout, hideProgress, showProgress } from '../RAFComponents/helpers/AppHelper';
import { useNavigate } from 'react-router-dom';
// import { default as microsoftLogo } from "./../../images/Microsoft_logo.svg";


const LoginPage = ({ }) => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const clientLogo = `${window.location.origin}/Content/img/SkytrustSecureIDLogo.png`;
    const userNameTextBox = useRef<TextBoxComponent>(null);
    const loginHint = getQueryString("loginHint");
    // let navigate = useNavigate();

    useEffect(() => {
        // console.log({ loginHint });
        if (!IsNullOrWhiteSpace(loginHint) && !isAuthenticated) {
            GetLoginFlowAndRedirect(loginHint);
        }
    }, [""]);

    // const loginSubmit = (event) => {
    //     event.preventDefault();
    // };

    const GetLoginFlowAndRedirect = (userName) => {
        if (!IsNullOrWhiteSpace(userName)) {

            let progressDiv = showProgress('body', true);
            return repositoryActions.postDataAndGetResponse(
                'User/GetUserLoginFlow',
                { UserName: userName },
                null,
                ContentType.applicationJson,
            ).then((userLoginFlowResponse) => {
                let userExist = true;
                if (isNotNullAndUndefined(userLoginFlowResponse) && isNotNullAndUndefined(userLoginFlowResponse.data) && !IsNullOrWhiteSpace(userLoginFlowResponse.data)) {
                    const userLoginFlowResponseNum = Number(userLoginFlowResponse.data);
                    switch (userLoginFlowResponseNum) {
                        case EmpLoginStatusValue.SecureIDPending:
                            loginRequest.authority = `${AzureAdB2C.Instance}/${AzureAdB2C.Domain}/${AzureAdB2C.DefaultSignUpPolicyId}`;
                            break;
                        case EmpLoginStatusValue.NewuserPending:
                            loginRequest.authority = `${AzureAdB2C.Instance}/${AzureAdB2C.Domain}/${AzureAdB2C.DefaultSignUpPolicyId}`;
                            break;
                        case EmpLoginStatusValue.MSSSOPending:
                            loginRequest.domainHint = "ms-secureid.skytrust.co";
                            loginRequest.authority = `${AzureAdB2C.Instance}/${AzureAdB2C.Domain}/${AzureAdB2C.MicrosoftSignUpSignInPolicyId}`;
                            break;
                        case EmpLoginStatusValue.SecureIDVerified:
                            loginRequest.authority = `${AzureAdB2C.Instance}/${AzureAdB2C.Domain}/${AzureAdB2C.DefaultSignInPolicyId}`;
                            break;
                        case UserLoginFlow.SecureID2FAVerified:
                            loginRequest.authority = `${AzureAdB2C.Instance}/${AzureAdB2C.Domain}/${AzureAdB2C.DefaultSignInMFAPolicyId}`;
                            break;
                        case EmpLoginStatusValue.MSSSOActivated:
                            loginRequest.domainHint = "ms-secureid.skytrust.co";
                            loginRequest.authority = `${AzureAdB2C.Instance}/${AzureAdB2C.Domain}/${AzureAdB2C.MicrosoftSignUpSignInPolicyId}`;
                            break;
                        case EmpLoginStatusValue.Unverified:
                            userExist = false;
                            break;
                        default:
                            break;
                    }
                }
                if (userExist === true) {
                    loginRequest.loginHint = userName;
                    instance.loginRedirect(loginRequest);
                    hideProgress(progressDiv);
                }
                else {
                    hideProgress(progressDiv);
                    if (IsNullOrWhiteSpace(loginHint)) {
                        showErrorToast('Unable to login with this user. Please contact administrator.');
                    }
                }

            });
        }
        else {
            showErrorToast("Enter the user name");
        }
    };

    const loginClick = (event) => {
        if (isNotNullAndUndefined(loginHint)) {
            loginRequest.loginHint = loginHint;
        }
        instance.loginRedirect(loginRequest);
    };

    const logoutClick = () => {
        //instance.logoutRedirect();
        Logout();
    };

    return (
        <>


            <div className="h-100">
                <div className="h-100 content panel p-0">
                    <div className="h-100 d-flex justify-content-lg-end justify-content-center">
                        <div className="right-section d-none d-lg-block min-vh-100">
                            <div className="h-100 position-relative">
                                <Suspense fallback={<SkyLoadingPanel loadingText="Loading..." />}>
                                    <LoginSlider />
                                </Suspense>
                            </div>
                        </div>
                        <div className="left-section bg-white">
                            <div className="loginContainer overflow-auto d-flex align-items-center flex-column h-100 customScrollBar">
                                <div className="w-100 formContainer d-flex flex-column align-items-center justify-content-center">
                                    <div className="text-center">
                                        <img src={clientLogo} alt="Product Logo" className="clientlogoImage mb-3" />
                                        <div className="d-flex justify-content-center align-items-center pb-2">
                                            <h3>Welcome to Skytrust Next Generation</h3>
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center pb-2">
                                            <h5>
                                                To access Skytrust environment, login with your User
                                                Name
                                            </h5>
                                        </div>
                                        {isAuthenticated && <ButtonComponent type="button" isPrimary cssClass='w-75' onClick={logoutClick}>Logout</ButtonComponent>}
                                        {(inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) &&
                                            // inProgress check prevents sign-in button from being displayed briefly after returning from a redirect sign-in. Processing the server response takes a render cycle or two
                                            <ButtonComponent type="button" isPrimary cssClass='w-75' onClick={loginClick}>Login</ButtonComponent>
                                        }
                                        {/* {(inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) &&
                                            <div className="d-flex justify-content-center align-items-center flex-column">

                                                <form
                                                    onSubmit={loginClick2}
                                                    className="d-flex justify-content-center align-items-center flex-column px-2 w-100">
                                                    <div className="form-group w-100">
                                                        <TextBoxComponent name={"Username"}
                                                            ref={userNameTextBox}
                                                            type="text" width={'100%'} showClearButton={true} placeholder={"Username"} value="" />

                                                    </div>
                                                    <ButtonComponent type="submit" isPrimary cssClass='w-75'>Login</ButtonComponent>
                                                </form>
                                                <div className="divider">
                                                    <h2>OR</h2>
                                                </div>
                                                

                                                <form
                                                    onSubmit={signInMSClick}
                                                    className="d-flex justify-content-center align-items-center flex-column px-2 w-100">
                                                    <ButtonComponent type="submit" cssClass='e-flat custom-button-md e-outline me-2 w-75 d-flex align-items-center p-0'>
                                                        <img src={microsoftLogo} height="30px" />
                                                        <div className='w-100 d-flex align-items-center justify-content-center' style={{ marginRight: '15px' }}>Sign in with Microsoft</div>
                                                    </ButtonComponent>
                                                </form>

                                            </div>
                                        } */}
                                        <div id="redirectDiv" className="hidden"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

// LoginPage.propTypes = {
//     redirectStartPage: PropTypes.string,
// };

export default LoginPage;