import { isNotNullAndUndefined } from "../../helpers/utils";
import { RAFChoiceListOption } from "./RAFChoiceListSettings";
import { RAFDataType } from './RAFDataType';

export class ValueJson {
    Id?: number;
    Name?: string;
    DisplayName?: string;
    ColorCode?: string;
    Category?: string;
    DisplayOrder?: number;
    Parent?: string;
    IconCss?: string;
    DependentList?: string[];
    Score?: number;
    Icon?: string;
}

export class QueryAttributeJM {
    UIType?: string;
    DisplayOrder?: number;
    EntityType?: string;
    RelatedEntities?: string;
    IsMasterDetail?: boolean;
    IsEditable?: boolean;
    IsDefault?: boolean;
    ValueJson?: ValueJson[];
    IsRequired?: boolean;
    Visible?: boolean;
    ShowInSelect?: boolean;
    IsMultiSelect?: boolean;
    PropertyName?: string;
    DataType?: string;
    DisplayName?: string;
    RefUID?: string;
    AttributeName?: string;
    AttributeUID?: string;
    ShowInWhere?: boolean;
    EnableMassUpdate?: boolean;

    FieldName?: string;
    DefaultValue?: string;

    isSelectedColumn?: boolean;
    entity?: string;
    EntityUID?: string;
    UISettings?: RatingSettings | ImageSettings | VideoSettings;

}

export class RatingSettings {
    Length?: number;
}

export class ImageSettings {
    Height?: string;
    Width?: string;
    Url?: string;
}

export class VideoSettings {
    Height?: string;
    Width?: string;
    Url?: string;
}

export class RAFOperator {
    static DefaultOperator: RAFChoiceListOption = { label: 'Default', value: 'Default' };
    static ContainsOperator: RAFChoiceListOption = { label: 'contains', value: 'Contains' };
    static NotContainsOperator: RAFChoiceListOption = { label: 'does not contain', value: 'NotContains' };
    static BeginsWithOperator: RAFChoiceListOption = { label: 'begins with', value: 'BeginsWith' };
    static NotBeginsWithOperator: RAFChoiceListOption = { label: 'does not begin with', value: 'NotBeginsWith' };
    static EndsWithOperator: RAFChoiceListOption = { label: 'ends with', value: 'EndsWith' };
    static NotEndsWithOperator: RAFChoiceListOption = { label: 'does not end with', value: 'NotEndsWith' };
    static BetweenOperator: RAFChoiceListOption = { label: 'is between', value: 'Between' };
    static NotBetweenOperator: RAFChoiceListOption = { label: 'is not between', value: 'NotBetween' };
    static EqualOperator: RAFChoiceListOption = { label: 'is', value: 'Equal' };
    static NotEqualOperator: RAFChoiceListOption = { label: 'is not', value: 'NotEqual' };
    static InOperator: RAFChoiceListOption = { label: 'is in', value: 'In' };
    static NotInOperator: RAFChoiceListOption = { label: 'is not in', value: 'NotIn' };
    static LesserThanOperator: RAFChoiceListOption = { label: 'is lesser than', value: 'LesserThan' };
    static LesserThanOrEqualOperator: RAFChoiceListOption = { label: 'is lesser than or equal to', value: 'LesserThanOrEqual' };
    static GreaterThanOperator: RAFChoiceListOption = { label: 'is greater than', value: 'GreaterThan' };
    static GreaterThanOrEqualOperator: RAFChoiceListOption = { label: 'is greater than or equal to', value: 'GreaterThanOrEqual' };
    static IsEmptyOperator: RAFChoiceListOption = { label: 'is empty', value: 'IsEmpty' };
    static IsNotEmptyOperator: RAFChoiceListOption = { label: 'is not empty', value: 'IsNotEmpty' };
    static IsNullOperator: RAFChoiceListOption = { label: 'is null', value: 'IsNull' };
    static IsNotNullOperator: RAFChoiceListOption = { label: 'is not null', value: 'IsNotNull' };

    static AndCondition = 'AND';
    static OrCondition = 'OR';

    static getOperators?(dataType?: string): RAFChoiceListOption[] {
        let retVal: RAFChoiceListOption[] = [];
        switch (dataType) {
            case RAFDataType.Boolean:
                retVal.push(
                    RAFOperator.EqualOperator,
                    RAFOperator.NotEqualOperator,
                    RAFOperator.IsEmptyOperator,
                    RAFOperator.IsNotEmptyOperator,
                );
                break;
            case RAFDataType.Date:
                retVal.push(
                    RAFOperator.EqualOperator,
                    RAFOperator.NotEqualOperator,
                    RAFOperator.BetweenOperator,
                    RAFOperator.NotBetweenOperator,
                    RAFOperator.GreaterThanOperator,
                    RAFOperator.LesserThanOperator,
                    RAFOperator.GreaterThanOrEqualOperator,
                    RAFOperator.LesserThanOrEqualOperator,
                    RAFOperator.IsEmptyOperator,
                    RAFOperator.IsNotEmptyOperator,
                );
                break;
            case RAFDataType.Dropdown:
                retVal.push(
                    RAFOperator.EqualOperator,
                    RAFOperator.NotEqualOperator,
                    RAFOperator.IsEmptyOperator,
                    RAFOperator.IsNotEmptyOperator,
                );
                break;
            case RAFDataType.Lookup:
                retVal.push(
                    RAFOperator.EqualOperator,
                    RAFOperator.NotEqualOperator,
                    RAFOperator.IsEmptyOperator,
                    RAFOperator.IsNotEmptyOperator,
                );
                break;
            case RAFDataType.Number:
                retVal.push(
                    RAFOperator.EqualOperator,
                    RAFOperator.NotEqualOperator,
                    RAFOperator.GreaterThanOperator,
                    RAFOperator.LesserThanOperator,
                    RAFOperator.GreaterThanOrEqualOperator,
                    RAFOperator.LesserThanOrEqualOperator,
                    RAFOperator.IsEmptyOperator,
                    RAFOperator.IsNotEmptyOperator,
                );
                break;
            default:
                retVal.push(
                    RAFOperator.EqualOperator,
                    RAFOperator.NotEqualOperator,
                    RAFOperator.ContainsOperator,
                    RAFOperator.NotContainsOperator,
                    RAFOperator.BeginsWithOperator,
                    RAFOperator.NotBeginsWithOperator,
                    RAFOperator.EndsWithOperator,
                    RAFOperator.NotEndsWithOperator,
                    RAFOperator.IsEmptyOperator,
                    RAFOperator.IsNotEmptyOperator,
                );
                break;
        }

        return retVal;
    }

    static getAllOperators?(): RAFChoiceListOption[] {
        let retVal: RAFChoiceListOption[] = [];
        retVal.push(
            RAFOperator.DefaultOperator,
            RAFOperator.ContainsOperator,
            RAFOperator.NotContainsOperator,
            RAFOperator.BeginsWithOperator,
            RAFOperator.NotBeginsWithOperator,
            RAFOperator.EndsWithOperator,
            RAFOperator.NotEndsWithOperator,
            RAFOperator.BetweenOperator,
            RAFOperator.NotBetweenOperator,
            RAFOperator.EqualOperator,
            RAFOperator.NotEqualOperator,
            RAFOperator.InOperator,
            RAFOperator.NotInOperator,
            RAFOperator.LesserThanOperator,
            RAFOperator.LesserThanOrEqualOperator,
            RAFOperator.GreaterThanOperator,
            RAFOperator.GreaterThanOrEqualOperator,
            RAFOperator.IsEmptyOperator,
            RAFOperator.IsNotEmptyOperator,
            RAFOperator.IsNullOperator,
            RAFOperator.IsNotNullOperator,
        );

        return retVal;
    }

    static getLabel?(value: string): string {
        let retVal = "";
        let allOperators: RAFChoiceListOption[] = this.getAllOperators();
        if (isNotNullAndUndefined(value)) {
            const objOperator = allOperators.find(x => x.value === value);
            if (isNotNullAndUndefined(objOperator)) {
                retVal = objOperator.label;
            }
        }
        return retVal;
    }
}